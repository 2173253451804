import { Link } from "gatsby";
import React, { useState } from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";

import "../styles/contact.scss";

const Contact = () => {
  const [name, setname] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [message, setmessage] = useState("");

  const onSubmit = async (event: any) => {
    event.preventDefault();
    try {
      let formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("message", message);
      let resp = await fetch("/", {
        method: "post",
        body: formData,
      });
      console.log("Response received:", resp);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>HelloAR: Ar and VR Company</title>
        <meta
          name="description"
          content="If you're looking for a Ar and VR Company, please reach out to us. We've worked with brands across domains and geographies."
        />
        <meta name="keywords" content="Ar and VR Company" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />
        <meta property="og:image" content="../../videos/thumbnail/3d_ar.jpg" />
      </Helmet>

      <div className="container-fluid contact">
        <div className="container">
          <div className="spacer"></div>
          <div className="spacer d-xl-none"></div>
          <div className="spacer-sm"></div>
          <div className="main row">
            <h1 className="h1">
              Thank you! Your response has been submitted. <br />
              Our team will reach out to you in next 24 hours.
            </h1>
          </div>
        </div>
        <div className="spacer"></div>
      </div>
    </Layout>
  );
};

export default Contact;
